import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Container, MainBody, FadeImage } from "./styles/Global.styled";
import { ThemeProvider } from "styled-components";
import { theme } from "./utils/Theme";
import Showcase from "./components/Showcase";
import MySkills from "./components/MySkills";
import MyProjects from "./components/MyProjects";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import TopFadeImage from "../src/assets/top.png";
import AnimatedCursor from "react-animated-cursor";
import Experience from "./components/Experience";
import { Toaster } from "react-hot-toast";
import Posts from "./components/Posts";
import BlogPost from "./components/BlogPost"; // Import the BlogPost component

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div>
          <Toaster />
        </div>
        <MainBody>
          <Navbar />
          <Container>
            <Routes>
              <Route
                path="/"
                element={
                  <>
                    <Showcase />
                    <Posts />
                    <Experience />
                    <MySkills />
                    <MyProjects />
                  </>
                }
              />
              <Route path="/diary/:slug" element={<BlogPost />} />
            </Routes>
          </Container>
          <FadeImage src={TopFadeImage} top="0" width="inherit" />
          <Footer/>
        </MainBody>
        <AnimatedCursor
          innerSize={16}
          outerSize={28}
          color="136, 171, 142"
          outerAlpha={0.2}
          innerScale={0.7}
          outerScale={5}
        />
      </Router>
    </ThemeProvider>
  );
}

export default App;
